// Currency code (ISO 4217) to use when displaying prices in the studio

import { CogIcon, ColorWheelIcon, ComposeIcon, SearchIcon } from '@sanity/icons'

import ShopifyIcon from './components/icons/Shopify'

// https://en.wikipedia.org/wiki/ISO_4217
export const DEFAULT_CURRENCY_CODE = 'GBP'

// Document types which:
// - cannot be created in the 'new document' menu
// - cannot be duplicated, unpublished or deleted
export const LOCKED_DOCUMENT_TYPES = ['settings', 'home', 'booking', 'allLocations', 'media.tag']

// Document types which:
// - cannot be created in the 'new document' menu
// - cannot be duplicated, unpublished or deleted
// - are from the Sanity Connect Shopify app - and can be linked to on Shopify
export const SHOPIFY_DOCUMENT_TYPES = ['product', 'productVariant', 'collection']

// References to include in 'internal' links
export const PAGE_REFERENCES = [
  { type: 'collection' },
  { type: 'home' },
  { type: 'page' },
  { type: 'product' },
  { type: 'postCategoryPage' },
  { type: 'journal' },
  { type: 'menu' },
  { type: 'cafe' },
  { type: 'permitRoom' },
  { type: 'storePage' },
  { type: 'allLocations' },
  { type: 'event' },
  { type: 'post' },
  { type: 'myth' },
]

// API version to use when using the Sanity client within the studio
// https://www.sanity.io/help/studio-client-specify-api-version
export const SANITY_API_VERSION = '2022-10-25'

// Your Shopify store ID.
// This is the ID in your Shopify admin URL (e.g. 'my-store-name' in https://admin.shopify.com/store/my-store-name).
// You only need to provide the ID, not the full URL.
// Set this to enable helper links in document status banners and shortcut links on products and collections.
export const SHOPIFY_STORE_ID = process.env.NEXT_PUBLIC_SHOPIFY_STORE_ID || ''

// Field groups used through schema types
export const GROUPS = [
  {
    name: 'theme',
    title: 'Theme',
    icon: ColorWheelIcon,
  },
  {
    name: 'general',
    title: 'General',
    icon: CogIcon,
  },
  {
    name: 'settings',
    title: 'Settings',
    icon: CogIcon,
  },
  {
    default: true,
    name: 'content',
    title: 'Content',
    icon: ComposeIcon,
  },
  {
    name: 'shopifySync',
    title: 'Shopify sync',
    icon: ShopifyIcon,
  },
  {
    name: 'seo',
    title: 'SEO',
    icon: SearchIcon,
  },
]

export const POST_CATEGORIES = [
  {
    title: 'Story',
    value: 'story',
  },
  {
    title: 'Recipe',
    value: 'recipe',
  },
  {
    title: 'Guide',
    value: 'guide',
  },
  {
    title: 'Interview',
    value: 'interview',
  },
  {
    title: 'Dishoom Loves',
    value: 'dishoom-loves',
  },
]

export const JOURNAL_CATEGORIES = [
  {
    title: 'All',
    value: 'all',
    pluralSlug: 'all',
  },
  {
    title: 'Story',
    value: 'story',
    pluralSlug: 'stories',
  },
  {
    title: 'Recipe',
    value: 'recipe',
    pluralSlug: 'recipes',
  },
  {
    title: 'Guide',
    value: 'guide',
    pluralSlug: 'guides',
  },
  {
    title: 'Interview',
    value: 'interview',
    pluralSlug: 'interviews',
  },
  {
    title: 'Dishoom Loves',
    value: 'dishoom-loves',
    pluralSlug: 'dishoom-loves',
  },
  {
    title: 'Founding myth',
    value: 'founding-myth',
    pluralSlug: 'myths',
  },
  {
    title: 'Event',
    value: 'event',
    pluralSlug: 'events',
  },
]

export const CAFE_LAYOUT_VARIANTS = [
  {
    title: 'Carnaby',
    value: 'carnaby',
  },
  {
    title: 'Kensington',
    value: 'kensington',
  },
  {
    title: 'Canary Wharf',
    value: 'canary-wharf',
  },
  {
    title: 'Covent Garden',
    value: 'covent-garden',
  },
  {
    title: 'Battersea',
    value: 'battersea',
  },
  {
    title: "King's Cross",
    value: 'kings-cross',
  },
  {
    title: 'Shoreditch',
    value: 'shoreditch',
  },
  {
    title: 'Manchester',
    value: 'manchester',
  },
  {
    title: 'Birmingham',
    value: 'birmingham',
  },
  {
    title: 'Edinburgh',
    value: 'edinburgh',
  },
]

export const HEADER_VARIANTS = [
  { title: 'Full Width + Background Media', value: 'fullWidth' },
  { title: 'Half Width', value: 'halfWidth' },
  { title: 'Product With Slider', value: 'productWidthSlider' },
]
export const FULL_WIDTH_HEADER_VARIANT = [
  { title: 'Medias', value: 'medias' },
  { title: 'Products', value: 'products' },
]
