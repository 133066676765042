import { type ClassValue, clsx } from 'clsx'
import { format, parseISO } from 'date-fns'
import type { Metadata, ResolvingMetadata } from 'next'
import { ReadonlyURLSearchParams } from 'next/navigation'
import { twMerge } from 'tailwind-merge'

import { urlForOpenGraphImage } from '@/sanity/lib/utils'
import { OpeningTimes } from '@/sanity/types'
import { Seo } from '@/types'

export const createUrl = (pathname: string, params: URLSearchParams | ReadonlyURLSearchParams) => {
  const paramsString = params.toString()
  const queryString = `${paramsString.length ? '?' : ''}${paramsString}`

  return `${pathname}${queryString}`
}

export const ensureStartsWith = (stringToCheck: string, startsWith: string) =>
  stringToCheck.startsWith(startsWith) ? stringToCheck : `${startsWith}${stringToCheck}`

export const validateEnvironmentVariables = () => {
  const requiredEnvironmentVariables = [
    'SHOPIFY_STORE_DOMAIN',
    'SHOPIFY_STOREFRONT_ACCESS_TOKEN',
    'SHOPIFY_CUSTOMER_ACCOUNT_API_CLIENT_ID',
    'SHOPIFY_CUSTOMER_ACCOUNT_API_URL',
    'SHOPIFY_CUSTOMER_API_VERSION',
    'SHOPIFY_ORIGIN_URL',
  ]
  const missingEnvironmentVariables = [] as string[]

  requiredEnvironmentVariables.forEach((envVar) => {
    if (!process.env[envVar]) {
      missingEnvironmentVariables.push(envVar)
    }
  })

  if (missingEnvironmentVariables.length) {
    throw new Error(
      `The following environment variables are missing. Your site will not work without them. Read more: https://vercel.com/docs/integrations/shopify#configure-environment-variables\n\n${missingEnvironmentVariables.join(
        '\n',
      )}\n`,
    )
  }

  if (
    process.env.SHOPIFY_STORE_DOMAIN?.includes('[') ||
    process.env.SHOPIFY_STORE_DOMAIN?.includes(']')
  ) {
    throw new Error(
      'Your `SHOPIFY_STORE_DOMAIN` environment variable includes brackets (ie. `[` and / or `]`). Your site will not work with them there. Please remove them.',
    )
  }
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const groupWordsInPairs = (text: string): string[][] => {
  const words = text.split(' ')
  const groupedWords: string[][] = []
  for (let i = 0; i < words.length; i += 2) {
    groupedWords.push(words.slice(i, i + 2))
  }
  return groupedWords
}

export type RemoveObject<T> = T extends unknown ? (keyof T extends never ? never : T) : never

export type ExtractBlock<
  TArray extends Array<{ _type: string }> | null,
  TType extends string,
> = Extract<NonNullable<TArray>[number], { _type: TType }>

// SEO metadata
export const generatePageMetadata = async (
  seo: Seo | null | undefined,
  parent?: ResolvingMetadata,
): Promise<Metadata> => {
  const ogImage = urlForOpenGraphImage(seo?.ogImage)

  return {
    title: seo?.metaTitle || '',
    description: seo?.metaDescription ? seo.metaDescription : '',
    openGraph: ogImage
      ? {
          images: [ogImage, ...(parent ? (await parent).openGraph?.images || [] : [])],
        }
      : {},
  }
}

export const currency = (number: number | string): string => {
  if (typeof number === 'string') {
    number = Number(number)
  }
  return Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  }).format(number)
}

export const slugifyTitle = (title: string) => title.toLowerCase().replace(/\s/g, '-')

export function convertTo12HourTime(time24) {
  // Split the input time string into hours and minutes
  const [hour, minute] = time24.split(':').map(Number)

  // Determine AM or PM based on the hour
  const period = hour >= 12 ? 'pm' : 'am'

  // Convert hour from 24-hour to 12-hour format
  const hour12 = hour % 12 === 0 ? 12 : hour % 12

  // Return the formatted time with the correct period
  return `${hour12}${minute === 0 ? '' : ':' + minute.toString().padStart(2, '0')}${period}`
}

export const convertTo24HourTime = (time) => {
  const [timePart, modifier] = time.split(/(am|pm)/i)
  let [hours, minutes] = timePart.split(':').map(Number)

  if (modifier.toLowerCase() === 'pm' && hours !== 12) {
    hours += 12
  } else if (modifier.toLowerCase() === 'am' && hours === 12) {
    hours = 0
  }

  return `${hours.toString().padStart(2, '0')}:${minutes ? minutes.toString().padStart(2, '0') : '00'}`
}

export const formatDateTimeISO = (dateString, timeString) => {
  // Parse the date string
  const parsedDate = parseISO(dateString)
  // Combine date and time
  const [hours, minutes] = timeString.split(':').map(Number)
  parsedDate.setHours(hours, minutes)
  // Format the date to ISO 8601 format
  return format(parsedDate, "yyyyMMdd'T'HHmmss")
}

export const calculateOpeningTimes = (openingTimes?: OpeningTimes) => {
  if (!openingTimes) {
    return 'Opening hours not available'
  }

  const date = new Date()
  const dayNumber = date.getDay()
  const adjustedDayNumber = dayNumber === 0 ? 6 : dayNumber - 1

  return (
    'Open today, ' +
    convertTo12HourTime(openingTimes[adjustedDayNumber].openingTime) +
    ' to ' +
    convertTo12HourTime(openingTimes[adjustedDayNumber].closingTime)
  )
}

function getOrdinalDaySuffix(day: number): string {
  if (day > 3 && day < 21) return 'th' // 11th, 12th, 13th, etc.
  switch (day % 10) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}

export function formatDate(dateString: string): string {
  const date = new Date(dateString)

  const day = date.getDate()
  const month = date.toLocaleString('default', { month: 'long' })
  const year = date.getFullYear()

  const dayWithSuffix = `${day}${getOrdinalDaySuffix(day)}`

  return `${dayWithSuffix} ${month} ${year}`
}

export function formatDateRange(startDateString: string, endDateString: string): string {
  const startDate = new Date(startDateString)
  const endDate = new Date(endDateString)

  const startDay = startDate.getDate()
  const endDay = endDate.getDate()
  const startMonth = startDate.toLocaleString('default', { month: 'long' })
  const endMonth = endDate.toLocaleString('default', { month: 'long' })
  const startYear = startDate.getFullYear()
  const endYear = endDate.getFullYear()

  const startDayWithSuffix = `${startDay}${getOrdinalDaySuffix(startDay)}`
  const endDayWithSuffix = `${endDay}${getOrdinalDaySuffix(endDay)}`

  if (startMonth === endMonth && startYear === endYear) {
    return `${startDayWithSuffix} — ${endDayWithSuffix} ${startMonth} ${startYear}`
  }

  // If dates are in different months/years, return the full date range like "24th September - 27th October"
  return `${startDayWithSuffix} ${startMonth} ${startYear} – ${endDayWithSuffix} ${endMonth} ${endYear}`
}

export function extractNumbers(input: string) {
  return input.replace(/[^\d]/g, '') // \d matches digits, ^ negates to exclude non-digits
}
