import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

// import { Color } from '@/sanity/types'

interface StoreState {
  isBookingOpened: boolean
  toggleBooking: () => void
  isMobileHeaderOpened: boolean
  setIsMobileHeaderOpened: (isMobileHeaderOpened: boolean) => void
  isHeaderTransparent: boolean
  setIsHeaderTransparent: (isHeaderTransparent: boolean) => void
  isSubNavOpened: 'explore' | 'menu' | 'cafes' | 'shop' | false
  setIsSubNavOpened: (isSubNavOpened: 'explore' | 'menu' | 'cafes' | 'shop' | false) => void
  // navBgColor: Color
  // setNavBgColor: (color: Color) => void
  isAudioPlayerOpened: boolean
  isAudioPlayerUrl: string
  setAudioPlayerOpened: (isAudioPlayerOpened: boolean, audioUrl: string) => void
}

const useStore = create<StoreState>()(
  devtools((set) => ({
    // booking
    isBookingOpened: false,
    toggleBooking: () => set((state) => ({ isBookingOpened: !state.isBookingOpened })),

    // header
    isMobileHeaderOpened: false,
    setIsMobileHeaderOpened: (isMobileHeaderOpened) => set({ isMobileHeaderOpened }),
    isHeaderTransparent: true,
    setIsHeaderTransparent: (isHeaderTransparent) => set({ isHeaderTransparent }),
    isSubNavOpened: false,
    setIsSubNavOpened: (isSubNavOpened) => set({ isSubNavOpened }),

    // navBgColor: {
    //   _type: 'color',
    //   hex: '#F0ECE0',
    // },
    // setNavBgColor: (color) => set({ navBgColor: color }),

    // audio-player
    isAudioPlayerOpened: false,
    isAudioPlayerUrl: '',
    setAudioPlayerOpened: (isAudioPlayerOpened: boolean, audioUrl: string) =>
      set({
        isAudioPlayerOpened,
        isAudioPlayerUrl: audioUrl,
      }),
  })),
)

export default useStore
