import createImageUrlBuilder from '@sanity/image-url'
import type { Image } from 'sanity'

import { dataset, projectId } from '@/sanity/lib/api'

import { JOURNAL_CATEGORIES } from '../constants'

const imageBuilder = createImageUrlBuilder({
  projectId: projectId || '',
  dataset: dataset || '',
})

export const urlForImage = (source: Image | undefined) => {
  // Ensure that source image contains a valid reference
  if (!source?.asset?._ref) {
    return undefined
  }

  return imageBuilder?.image(source).auto('format').fit('max')
}

export function urlForOpenGraphImage(image: Image | undefined, width = 1200, height = 627) {
  if (!image) return
  const url = urlForImage(image)?.width(1200).height(627).fit('crop').url()
  if (!url) return
  return { url, alt: image?.alt as string, width, height }
}

export function resolveHref({
  _type,
  slug,
  category,
  parentPageSlug,
}: {
  _type?: string
  slug?: string
  category?: string // Post type (e.g. 'post', 'recipe', etc.)
  parentPageSlug?: string
}): string {
  switch (_type) {
    case 'home':
      return '/'
    case 'page':
      if (!slug) return '/'

      if (parentPageSlug) {
        return `/${parentPageSlug}/${slug}/`
      }

      if (slug === 'store') {
        return 'https://store.dishoom.com'
      }

      return `/${slug}/`
    case 'allLocations':
      return '/locations/'
    case 'cafe':
      return slug ? `/${slug}/` : '/'
    case 'permitRoom':
      if (!slug) return '/permit-rooms/'

      if (slug === 'brighton') {
        return 'https://www.permitroom.co.uk/locations/brighton'
      }

      if (slug === 'cambridge') {
        return 'https://www.permitroom.co.uk/locations/cambridge'
      }

      if (slug === 'oxford') {
        return 'https://www.permitroom.co.uk/locations/oxford'
      }
    case 'journal':
      return '/journal/'
    case 'postCategoryPage':
      return slug ? `/journal/${slug}/` : '/'
    case 'subCategory':
      return slug && category ? `/journal/${category}/category/${slug}/` : '/'
    case 'myth':
      return slug ? `/journal/myths/${slug}/` : '/'
    case 'event':
      return slug ? `/journal/events/${slug}/` : '/'
    case 'post':
      const postTypeBase = JOURNAL_CATEGORIES.find((t) => t.value === category)?.pluralSlug
      return slug ? `/journal/${postTypeBase}/${slug}/` : '/'
    case 'menu':
      return slug ? `/menu/${slug}/` : '/'
    case 'storePage':
      return 'https://store.dishoom.com/'
    case 'product':
      // return slug ? `/store/products/${slug}/` : '/'
      return slug ? `https://store.dishoom.com/products/${slug}/` : '/'
    case 'collection':
      // return slug ? `/store/collections/${slug}/` : '/'
      return slug ? `https://store.dishoom.com/collections/${slug}/` : '/'
    default:
      console.warn('Invalid document type:', _type)
      return '#'
  }
}
