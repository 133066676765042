type IconProps = {
  variant:
    | 'bookmark'
    | 'share'
    | 'heart'
    | 'filter'
    | 'info'
    | 'search'
    | 'close'
    | 'arrow'
    | 'divider'
    | 'diamond'
    | 'save'
    | 'location'
    | 'phone'
    | 'clock'
    | 'spinner'
    | 'external-arrow'
    | 'link'
  className?: string
}

const Icon = ({ variant, className }: IconProps) => {
  switch (variant) {
    case 'bookmark':
      return (
        <svg
          className={className}
          width="12"
          height="16"
          viewBox="0 0 12 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.5 15.0657V0.5H11.5V15.0657L6.27735 11.584C6.1094 11.472 5.8906 11.472 5.72265 11.584L0.5 15.0657Z"
            stroke="currentColor"
            strokeLinejoin="round"
          />
        </svg>
      )
    case 'share':
      return (
        <svg
          className={className}
          width="13"
          height="16"
          viewBox="0 0 13 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 9L6 9.5L7 9.5L7 9L6 9ZM6.85355 0.646446C6.65829 0.451184 6.34171 0.451184 6.14645 0.646446L2.96447 3.82843C2.7692 4.02369 2.7692 4.34027 2.96447 4.53553C3.15973 4.7308 3.47631 4.7308 3.67157 4.53553L6.5 1.70711L9.32843 4.53553C9.52369 4.7308 9.84027 4.7308 10.0355 4.53553C10.2308 4.34027 10.2308 4.02369 10.0355 3.82843L6.85355 0.646446ZM7 9L7 1L6 1L6 9L7 9Z"
            fill="currentColor"
          />
          <path d="M1 9V15H12V9" stroke="currentColor" strokeLinejoin="round" />
        </svg>
      )
    case 'heart':
      return (
        <svg
          className={className}
          width="18"
          height="16"
          viewBox="0 0 18 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.9879 1C11.1788 1 9.73118 2.05 9.00743 3.1C8.28368 2.05 6.83623 1.0008 5.02661 1.00047C2.49274 1 0.684716 3.1 1.046 5.9C1.49636 9.39043 5.3883 12.9 9.00723 15C12.6258 12.9 16.6071 9.4 16.9686 5.9C17.2578 3.1 15.5213 1 12.9879 1Z"
            stroke="currentColor"
          />
        </svg>
      )
    case 'filter':
      return (
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="20"
          viewBox="0 0 15 20"
          fill="none"
        >
          <rect x="2" width="1" height="20" fill="currentColor" />
          <rect x="7" width="1" height="20" fill="currentColor" />
          <rect x="12" width="1" height="20" fill="currentColor" />
          <circle cx="2.5" cy="13.5" r="1.5" fill="#F0ECE0" stroke="currentColor" />
          <circle cx="7.5" cy="5.5" r="1.5" fill="#F0ECE0" stroke="currentColor" />
          <circle cx="12.5" cy="13.5" r="1.5" fill="#F0ECE0" stroke="currentColor" />
        </svg>
      )
    case 'info':
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11 9H13V7H11M12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM11 17H13V11H11V17Z"
            fill=""
          />
        </svg>
      )
    case 'search':
      return (
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="18"
          viewBox="0 0 19 18"
          fill="none"
        >
          <ellipse cx="7.88202" cy="7.58708" rx="6.88202" ry="6.58708" stroke="currentColor" />
          <path
            d="M16.7566 17.6917C17.1556 18.0736 17.8024 18.0736 18.2014 17.6917C18.6004 17.3099 18.6004 16.6907 18.2014 16.3088L16.7566 17.6917ZM11.8987 13.042L16.7566 17.6917L18.2014 16.3088L13.3435 11.6591L11.8987 13.042Z"
            fill="currentColor"
          />
        </svg>
      )
    case 'close':
      return (
        <svg
          width="15"
          height="16"
          viewBox="0 0 15 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            x1="10.4297"
            y1="4.28765"
            x2="3.35868"
            y2="11.3587"
            stroke="#353839"
            strokeWidth="1.5"
          />
          <line
            x1="3.35943"
            y1="4.6413"
            x2="10.4305"
            y2="11.7124"
            stroke="#353839"
            strokeWidth="1.5"
          />
        </svg>
      )
    case 'arrow':
      return (
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          width={34}
          height={34}
          fill="none"
        >
          <rect width={34} height={34} fill="#F0ECE0" rx={17} transform="matrix(1 0 0 -1 0 34)" />
          <rect
            width={33}
            height={33}
            x={0.5}
            y={-0.5}
            stroke="#C4BFAF"
            strokeOpacity={0.5}
            rx={16.5}
            transform="matrix(1 0 0 -1 0 33)"
          />
          <path fill="#353839" d="M12.41 13.59 17 18.17l4.59-4.58L23 15l-6 6-6-6 1.41-1.41Z" />
        </svg>
      )
    case 'diamond':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width=""
          height=""
          viewBox="0 0 1326 35"
          fill="none"
        >
          <rect
            x="17.1213"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 17.1213 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="28.4358"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 28.4358 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="46.8127"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 46.8127 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="58.1272"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 58.1272 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="76.5041"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 76.5041 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="87.8186"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 87.8186 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="106.196"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 106.196 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="117.51"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 117.51 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="135.885"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 135.885 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="147.199"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 147.199 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="165.576"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 165.576 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="176.891"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 176.891 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="195.268"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 195.268 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="206.582"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 206.582 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="224.959"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 224.959 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="236.274"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 236.274 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="254.651"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 254.651 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="265.965"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 265.965 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="284.342"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 284.342 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="295.656"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 295.656 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="314.033"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 314.033 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="325.348"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 325.348 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="343.723"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 343.723 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="355.037"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 355.037 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="373.414"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 373.414 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="384.729"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 384.729 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="403.106"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 403.106 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="414.42"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 414.42 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="432.797"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 432.797 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="444.112"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 444.112 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="462.489"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 462.489 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="473.803"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 473.803 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="492.18"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 492.18 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="503.494"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 503.494 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="521.871"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 521.871 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="533.186"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 533.186 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="551.561"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 551.561 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="562.875"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 562.875 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="581.252"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 581.252 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="592.567"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 592.567 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="610.944"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 610.944 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="622.258"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 622.258 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="640.635"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 640.635 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="651.949"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 651.949 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="670.326"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 670.326 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="681.641"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 681.641 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="700.018"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 700.018 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="711.332"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 711.332 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="729.707"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 729.707 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="741.022"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 741.022 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="759.399"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 759.399 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="770.713"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 770.713 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="789.09"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 789.09 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="800.405"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 800.405 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="818.781"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 818.781 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="830.096"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 830.096 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="848.473"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 848.473 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="859.787"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 859.787 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="878.164"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 878.164 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="889.479"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 889.479 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="907.856"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 907.856 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="919.17"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 919.17 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="937.545"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 937.545 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="948.86"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 948.86 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="967.237"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 967.237 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="978.551"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 978.551 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="996.928"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 996.928 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="1008.24"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 1008.24 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="1026.62"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 1026.62 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="1037.93"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 1037.93 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="1056.31"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 1056.31 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="1067.63"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 1067.63 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="1086"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 1086 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="1097.32"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 1097.32 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="1115.69"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 1115.69 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="1127.01"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 1127.01 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="1145.38"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 1145.38 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="1156.7"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 1156.7 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="1175.07"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 1175.07 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="1186.39"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 1186.39 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="1204.77"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 1204.77 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="1216.08"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 1216.08 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="1234.46"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 1234.46 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="1245.77"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 1245.77 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="1264.15"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 1264.15 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="1275.46"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 1275.46 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="1293.84"
            y="17.4727"
            width="21"
            height="21"
            transform="rotate(-45 1293.84 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
          <rect
            x="1305.15"
            y="17.4727"
            width="5"
            height="5"
            transform="rotate(-45 1305.15 17.4727)"
            stroke="#7F937A"
            stroke-width="3"
          />
        </svg>
      )
    case 'divider':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="233"
          height="23"
          viewBox="0 0 233 23"
          fill="none"
        >
          <line y1="9.12549" x2="233" y2="9.12549" stroke="#353839" />
          <line y1="13.1255" x2="233" y2="13.1255" stroke="#353839" />
          <rect width="43" height="22" transform="translate(95 0.625488)" fill="#F0ECE0" />
          <path
            d="M105 11.6255L110.75 2.09921L122.25 2.09921L128 11.6255L122.25 21.1518L110.75 21.1518L105 11.6255Z"
            fill="#F3B85B"
          />
        </svg>
      )
    case 'location':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          className={className}
        >
          <path
            d="M12 6.5C12.663 6.5 13.2989 6.76339 13.7678 7.23223C14.2366 7.70107 14.5 8.33696 14.5 9C14.5 9.3283 14.4353 9.65339 14.3097 9.95671C14.1841 10.26 13.9999 10.5356 13.7678 10.7678C13.5356 10.9999 13.26 11.1841 12.9567 11.3097C12.6534 11.4353 12.3283 11.5 12 11.5C11.337 11.5 10.7011 11.2366 10.2322 10.7678C9.76339 10.2989 9.5 9.66304 9.5 9C9.5 8.33696 9.76339 7.70107 10.2322 7.23223C10.7011 6.76339 11.337 6.5 12 6.5ZM12 2C13.8565 2 15.637 2.7375 16.9497 4.05025C18.2625 5.36301 19 7.14348 19 9C19 14.25 12 22 12 22C12 22 5 14.25 5 9C5 7.14348 5.7375 5.36301 7.05025 4.05025C8.36301 2.7375 10.1435 2 12 2ZM12 4C10.6739 4 9.40215 4.52678 8.46447 5.46447C7.52678 6.40215 7 7.67392 7 9C7 10 7 12 12 18.71C17 12 17 10 17 9C17 7.67392 16.4732 6.40215 15.5355 5.46447C14.5979 4.52678 13.3261 4 12 4Z"
            fill="#353839"
          />
        </svg>
      )
    case 'phone':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          className={className}
        >
          <path
            d="M5.51667 8.99167C6.71667 11.35 8.65 13.2833 11.0083 14.4833L12.8417 12.65C13.075 12.4167 13.4 12.35 13.6917 12.4417C14.625 12.75 15.625 12.9167 16.6667 12.9167C16.8877 12.9167 17.0996 13.0045 17.2559 13.1607C17.4122 13.317 17.5 13.529 17.5 13.75V16.6667C17.5 16.8877 17.4122 17.0996 17.2559 17.2559C17.0996 17.4122 16.8877 17.5 16.6667 17.5C12.9094 17.5 9.30609 16.0074 6.64932 13.3507C3.99256 10.6939 2.5 7.09057 2.5 3.33333C2.5 3.11232 2.5878 2.90036 2.74408 2.74408C2.90036 2.5878 3.11232 2.5 3.33333 2.5H6.25C6.47101 2.5 6.68298 2.5878 6.83926 2.74408C6.99554 2.90036 7.08333 3.11232 7.08333 3.33333C7.08333 4.375 7.25 5.375 7.55833 6.30833C7.65 6.6 7.58333 6.925 7.35 7.15833L5.51667 8.99167Z"
            fill="#353839"
          />
        </svg>
      )
    case 'clock':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          className={className}
        >
          <path
            d="M10.0013 16.6665C11.7694 16.6665 13.4651 15.9641 14.7153 14.7139C15.9656 13.4636 16.668 11.7679 16.668 9.99984C16.668 8.23173 15.9656 6.53603 14.7153 5.28579C13.4651 4.03555 11.7694 3.33317 10.0013 3.33317C8.23319 3.33317 6.5375 4.03555 5.28726 5.28579C4.03701 6.53603 3.33464 8.23173 3.33464 9.99984C3.33464 11.7679 4.03701 13.4636 5.28726 14.7139C6.5375 15.9641 8.23319 16.6665 10.0013 16.6665ZM10.0013 1.6665C11.0957 1.6665 12.1793 1.88205 13.1903 2.30084C14.2014 2.71963 15.12 3.33346 15.8939 4.10728C16.6677 4.8811 17.2815 5.79976 17.7003 6.81081C18.1191 7.82185 18.3346 8.90549 18.3346 9.99984C18.3346 12.21 17.4567 14.3296 15.8939 15.8924C14.3311 17.4552 12.2114 18.3332 10.0013 18.3332C5.39297 18.3332 1.66797 14.5832 1.66797 9.99984C1.66797 7.7897 2.54594 5.67008 4.10875 4.10728C5.67155 2.54448 7.79116 1.6665 10.0013 1.6665ZM10.418 5.83317V10.2082L14.168 12.4332L13.543 13.4582L9.16797 10.8332V5.83317H10.418Z"
            fill="#353839"
          />
        </svg>
      )
    case 'save':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="14"
          viewBox="0 0 13 14"
          fill="none"
          className={className}
        >
          <line x1="6.5" y1="0.5" x2="6.5" y2="13.5" stroke="currentColor" />
          <line x1="13" y1="7" x2="-4.37114e-08" y2="7" stroke="currentColor" />
        </svg>
      )
    case 'spinner':
      return (
        <svg
          className="h-5 w-5 animate-spin text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )
    case 'external-arrow':
      return (
        <svg
          width="11"
          height="11"
          viewBox="0 0 11 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <path d="M1.5 9L9.5 1" stroke="#353839" stroke-width="2" />
          <path d="M10.5 0H2.5L10.5 8V0Z" fill="#353839" />
        </svg>
      )
    case 'link':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 24 24"
          className={className}
        >
          <g stroke="currentColor" strokeLinecap="round" strokeWidth="1.5">
            <path d="M10.046 14c-1.506-1.512-1.37-4.1.303-5.779l4.848-4.866c1.673-1.68 4.25-1.816 5.757-.305 1.506 1.512 1.37 4.1-.303 5.78l-2.424 2.433"></path>
            <path d="M13.954 10c1.506 1.512 1.37 4.1-.303 5.779l-2.424 2.433-2.424 2.433c-1.673 1.68-4.25 1.816-5.757.305-1.506-1.512-1.37-4.1.303-5.78l2.424-2.433"></path>
          </g>
        </svg>
      )
    default:
      return null
  }
}

export default Icon
